import { styled } from "styled-components";
import { DashboardMainContainer } from "../../CommonStyle/dashboardMain.style";
export const MainContainer = styled(DashboardMainContainer)`
  height: 100%;
  margin-top: 0;
  overflow: scroll;
  h3 {
    color: #fafafc;
  }
  .Profile {
    margin-top: 2rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 1rem;
  margin-bottom: 1.5rem;
  label {
    color: #fafafc;
  }
  input {
    background-color: #18181a;
    padding: 1.25rem;
    border-radius: 0.75rem;
    color: #fafafc;
  }
  textarea {
    background-color: #18181a;
    padding: 1.25rem;
    border-radius: 0.75rem;
    color: #fafafc;
  }
`;
